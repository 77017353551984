import React, { useContext } from "react";
import Layout from "../../../../components/Layout/layout";
import WhyMercedesTaxi from "../../../../components/Blog-Detail/whymercedestaxi"

const MercedesTaxi = () => {
    return (
        <Layout>
            <WhyMercedesTaxi />
        </Layout>
    );
};

export default MercedesTaxi;